import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';

import { useMe } from '@/hooks/useMe';

function Home() {
	const { push } = useRouter();

	const { isLoggedIn } = useMe();

	useEffect( () => {
		if ( isLoggedIn ) {
			push( '/dashboard' );
		}
	}, [ isLoggedIn, push ] );

	return (
		<Head>
			<title>CPP Application | Rural Mutual Insurance</title>
			
			<link rel="icon" href="/favicon.ico" />
			<link rel="icon" type="image/png" sizes="32x32" href="/images/favicon-32x32.png" />
			<link rel="icon" type="iamge/png" sizes="16x16" href="/images/favicon-16x16.png" />
		</Head>
	);
}

// Example for getting a new page layout
// Home.getLayout = function getLayout( page: ReactElement ) {
// 	return (
// 		<Main>
// 			{ page }
// 		</Main>
// 	);
// }

export default Home;
